import Image from 'next/image'
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'

import { IntoUser } from '@models/IntoUser'
import { UserListModal } from './UserListModal'

const UserListLoading = ({ loadingLimit }: { loadingLimit: number }) => {
  return (
    <div className="flex animate-pulse -space-x-1.5">
      {Array.from(Array(loadingLimit).keys()).map(i => (
        <div
          key={`user-loading-${i}`}
          className="inline-block aspect-square size-11 shrink-0 rounded-full border-2 border-contrast bg-tertiary object-cover light:bg-tertiary"
        />
      ))}
    </div>
  )
}

interface UserListProps {
  showUserPreview?: boolean
  users: (IntoUser | undefined)[]
  source?: string
}

export interface UserListRef {
  toggleUserModalVisibility: () => void
}

const UserList = forwardRef<UserListRef, UserListProps>(({ users, showUserPreview = true, source }, ref) => {
  const [isUserModalVisible, setUserModalVisibility] = useState(false)
  const toggleUserModalVisibility = useCallback(() => {
    setUserModalVisibility(isUserModalVisible => !isUserModalVisible)
  }, [])

  useImperativeHandle(ref, () => ({
    toggleUserModalVisibility,
  }))

  if (users?.length === 0) return <></>
  return (
    <>
      <div
        onClick={toggleUserModalVisibility}
        className="flex h-11 w-fit cursor-pointer flex-wrap -space-x-1.5 overflow-hidden"
      >
        {showUserPreview &&
          users.map(user =>
            user ? (
              <Image
                key={user.user_id}
                className="inline-block aspect-square size-11 shrink-0 rounded-full border-2 border-secondary bg-tertiary object-cover"
                src={user.photo_url ?? ''}
                alt={user.display_name}
                height={44}
                width={44}
              />
            ) : (
              <></>
            )
          )}
      </div>
      <UserListModal users={users} isVisible={isUserModalVisible} onClose={toggleUserModalVisibility} source={source} />
    </>
  )
})
UserList.displayName = 'UserList'

export { UserList, UserListLoading }
