import { ReactElement, useEffect, useState } from 'react'

import { UserListItem, UserListItemLoading } from '@common/UserListItem'
import userApi from '@redux/api/userApi'

export const UrlContextUser = ({
  heading,
  userPayload,
  children,
  hideFollowButton,
}: {
  heading?: string
  userPayload: { username: string } | { userId: number }
  children?: ReactElement
  hideFollowButton?: boolean
}) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  const { data: user, isLoading } = userApi.useGetUserDetailsQuery(userPayload)

  if (!user) return <></>
  return (
    <div className="space-y-4">
      <div className="text-sm opacity-50">{heading}</div>
      {isLoading ? (
        <div className="h-14">
          <UserListItemLoading />
        </div>
      ) : (
        <div className={`animate-fade-in opacity-0 duration-500 ${loaded ? 'opacity-100' : ''}`}>
          <UserListItem userId={user.user_id} hideFollowButton={hideFollowButton} source="url-profile-context-user">
            {children}
          </UserListItem>
        </div>
      )}
    </div>
  )
}
